<template>
  <div class="ActivityList">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            placeholder="选择集配中心"
            v-model="from.logistic_business_id"
            clearable
            filterable
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">活动时间：</div>
          <el-date-picker
            v-model="from.time"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="活动时间起"
            end-placeholder="活动时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.name"
            placeholder="请输入活动名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.open_status"
            clearable
            placeholder="请选择活动状态"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="停用" value="0"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="from.type" clearable placeholder="请选择活动类型">
            <!-- <el-option label="大促" value="1"></el-option> -->
            <el-option label="专题" value="2"></el-option>
            <!-- <el-option label="秒杀" value="3"></el-option> -->
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.is_audit"
            clearable
            placeholder="请选择是否需要运营审核"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">预提报时间：</div>
          <el-date-picker
            v-model="from.sign_time"
            :time-arrow-control="true"
          

            type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="预提报时间起"
            end-placeholder="预提报时间止"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="add(false)" type="primary">新增</el-button>
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" align="center" label="活动ID">
        </el-table-column>
        <el-table-column prop="name" align="center" label="活动名称">
        </el-table-column>
        <el-table-column prop="start_time" align="center" label="活动时间">
          <template slot-scope="scope">
            <div>{{ scope.row.start_time }}至{{ scope.row.end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sign_start_time"
          align="center"
          label="预提报时间"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.sign_start_time }}至{{ scope.row.sign_end_time }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="活动类型">
            <template slot-scope="scope">
            <div>
              {{typename(scope.row.type)  }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="desc" align="center" label="活动描述">
        </el-table-column>
        <el-table-column prop="is_audit" align="center" label="是否需审核">
            <template slot-scope="scope">
            <div>
              {{ scope.row.is_audit?'是':'否' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="open_status" align="center" label="活动状态">
            <template slot-scope="scope">
            <div>
              {{ scope.row.open_status?'开启':'停用' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="business_user" align="center" label="创建人">
          <template slot-scope="scope">
            <div>
              {{ scope.row?.business_user.username }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="goodslist(scope.row)">商品列表</el-button>
            <el-button type="text" @click="add(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleEdit(scope.row)">{{scope.row.open_status == 0 ? "开启" : "停用" }}</el-button>
            <el-button type="text" @click="onHandleQrCode(scope.row)">二维码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
      <script>
      import { getMiniAppQrCode } from "@/api/export";
      import { downLoadFlowBlob } from "@/utils/tools/base";
      import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";
export default {
  name: "ActivityList",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        logistic_business_id: "", //	T文本	是	集配中心id
        time: [], //	T文本	是	[2023-10-1.2024-11-1]活动时间
        sign_time: [], //	T文本	是	[2023-10-1.2024-11-1]提报时间
        name: "", //	T文本	是	名称
        open_status: "", //	T文本	是	活动状态，1-开启，0-停用
        type: "", //	T文本	是	类型 1-大促 2-专题 3-秒杀
        is_audit: "", //	T文本	是	是否需审核，1-是，0-否
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      options: [],
      optionsUserd: [],
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getBusinessList();
  },
  methods: {
    handleEdit( row) {
      this.$confirm(
        row.open_status === 1 ? "确定要停用吗？" : "确定要开启吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
            this.$api.decoration
            .activity_open_close({
              id: row.id,
              open_status: row.open_status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "停用或者开启");
              this.$message({
                type: "success",
                message: row.open_status === 1 ? "停用成功" : "开启成功",
              });
              this.hqlist()
            });
        })
        .catch(() => {});
    },
    /**
     * 展示二维码
     */
     async onHandleQrCode(row) {
      const { id } = row;
      const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: cate.envCode,
        type: 3,
        from_page:`pages/activityModule/index?id=${id}`
      };
      try {
        const res = await getMiniAppQrCode(params);
        console.log("ajax getMiniAppQrCode", res);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
      /** 前端生成二维码逻辑，已弃 */
      // const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      // const url = cate?.value && `${cate.value}?firstId=${pid}&secondId=${id}`;
      // const params = {
      //   url,
      //   name,
      // };
      // this.$refs.refQrCode.onInitData(params);
      // console.log("🆒 onHandleQrCode", row, params);
    },
    typename(e){
if(e==1){
return '大促'
}else if(e==2){
return '专题'
}else{
return '秒杀'
}
    },
    // 获取集配中心数据
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },

    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportservicesteam(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    add(e) {
      this.$router.push({
        name: "AddActivity",
        query: { id: e.id },
      });
    },
    goodslist(e) {
      this.$router.push({
        name: "ActivitgoodsList",
      });
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.decoration
        .activity_list(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
      <style lang="scss">
.ActivityList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 10px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
      
    